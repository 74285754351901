import * as Types from '../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Wed Apr 09 2025 15:58:39 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type CreateOnboardingActivityMutationVariables = Types.Exact<{
  input: Types.CreateOnboardingActivityInput;
}>;


export type CreateOnboardingActivityMutation = { __typename?: 'Mutation', createOnboardingActivity?: { __typename?: 'CreateOnboardingActivityPayload', onboardingActivity: { __typename?: 'OnboardingActivity', id: string, action: Types.OnboardingActivityAction, featureId: string } } | null | undefined };


export const CreateOnboardingActivityDocument = gql`
    mutation CreateOnboardingActivity($input: CreateOnboardingActivityInput!) {
  createOnboardingActivity(input: $input) {
    onboardingActivity {
      id
      action
      featureId
    }
  }
}
    `;
export type CreateOnboardingActivityMutationFn = Apollo.MutationFunction<CreateOnboardingActivityMutation, CreateOnboardingActivityMutationVariables>;

/**
 * __useCreateOnboardingActivityMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingActivityMutation, { data, loading, error }] = useCreateOnboardingActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnboardingActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnboardingActivityMutation, CreateOnboardingActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnboardingActivityMutation, CreateOnboardingActivityMutationVariables>(CreateOnboardingActivityDocument, options);
      }
export type CreateOnboardingActivityMutationHookResult = ReturnType<typeof useCreateOnboardingActivityMutation>;
export type CreateOnboardingActivityMutationResult = Apollo.MutationResult<CreateOnboardingActivityMutation>;
export type CreateOnboardingActivityMutationOptions = Apollo.BaseMutationOptions<CreateOnboardingActivityMutation, CreateOnboardingActivityMutationVariables>;